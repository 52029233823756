import women_home from "../assets/women_home.png";
import web_elements from "../assets/web_elements.png";
import Applified_logo from "../assets/Applified_logo.png";
import logonew from "../assets/LogoNew.png";
import curv from "../assets/curv.png";
import curv_olivgreen from "../assets/curv_olivgreen.png";
import curv_darkgreen from "../assets/curv_darkgreen.png";
import curve_orange from "../assets/curv_orange_updated.png";
import empathy_icon from "../assets/empathy_icon.png";
import probem_icon from "../assets/probem_icon.png";
import solution from "../assets/solution.png";
import trial from "../assets/trial.png";

import costeffectivesol from "../assets/costeffectivesol.png";
import webhost from "../assets/webhost.png";
import content from "../assets/content.png";
import brand from "../assets/brand.png";
import socialmediaicon from "../assets/socialmediaicon.png";

import tools from "../assets/tools.svg";
import webapps from "../assets/webapps.png";
import mobileapps from "../assets/mobileapps.png";
import software from "../assets/software.png";
import customapp from "../assets/customapp.png";

import tges from "../assets/tges.png";
import dronstudy from "../assets/dronstudy.png";
import grandtrunk from "../assets/grandtrunk.png";
import greenchoicenow from "../assets/greenchoicenow.png";
import bsgforsdg from "../assets/bsgforsdg.png";
import elderaid from "../assets/elderaid.png";

import tick from "../assets/tick.png";
import ecommerce from "../assets/ecommerce.jpeg";
import hospital from "../assets/hospital.jpeg";
import Entertainment from "../assets/Entertainment.webp";
import Distribution from "../assets/Distribution.avif";
import Franchise from "../assets/Franchise.jpeg";
import newlogo from "../assets/New logo Applified.png";

import webdevelopement from "../assets/webdevelopement.png";
import mobile from "../assets/mobile-removebg.png";
import erp from "../assets/erp-solution-icon-design-vector-removebg-preview.png";
import odoo from "../assets/odoo_logo.png";
import ourmissionSvg from "../assets/undraw_team-goals_0026.svg";
import projectplan from "../assets/planning-orange-icon.png";
import analytices from "../assets/analytics-big-data.png";
import configuree from "../assets/user-or-account-setting-icon.png";
import training from "../assets/training.png";
import training2 from "../assets/training2.png";
import testing from "../assets/testing.png";
import testing2 from "../assets/testing2.png";
import maintenance from "../assets/maintenance.png";
import mainn from "../assets/main.png";

export default {
  women_home,
  web_elements,
  curv,
  curv_darkgreen,
  curv_olivgreen,
  curve_orange,
  Applified_logo,
  logonew,
  newlogo,
  ourmissionSvg,
  projectplan,
  analytices,
  configuree,
  training,
  training2,
  testing,
  testing2,
  maintenance,
  mainn,

  empathy_icon,
  probem_icon,
  solution,
  trial,
  webdevelopement,
  mobile,
  erp,
  odoo,

  costeffectivesol,
  webhost,
  content,
  brand,
  socialmediaicon,

  tools,
  webapps,
  mobileapps,
  software,
  customapp,

  tges,
  dronstudy,
  grandtrunk,
  greenchoicenow,
  bsgforsdg,
  elderaid,

  tick,
  ecommerce,
  hospital,
  Entertainment,
  Distribution,
  Franchise,
};
